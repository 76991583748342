import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Flashcards from '../components/Flashcards/Flashcards';
import { getStackWithFlashcards, getUserStats } from '../services/firestoreService';
import { Button } from "../components/ui/button";
import { Flame } from 'lucide-react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app, auth } from '../firebase';
import Header from '../components/Header/Header';
import './StudyPage.css';

const StudyPage = () => {
  const { stackId } = useParams();
  const [stack, setStack] = useState(null);
  const navigate = useNavigate();
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [answerTracker, setAnswerTracker] = useState({});
  const [showMultiplierModal, setShowMultiplierModal] = useState(false);
  const [multipliers, setMultipliers] = useState(null);
  const [totalMultiplier, setTotalMultiplier] = useState(1);

  useEffect(() => {
    const fetchStackAndMultipliers = async () => {
      try {
        const stackData = await getStackWithFlashcards(stackId);
        setStack(stackData);

        // Get multipliers from backend
        const functions = getFunctions(app);
        const getCurrentMultipliers = httpsCallable(functions, 'getCurrentMultipliers');
        const result = await getCurrentMultipliers({ totalCards: stackData.flashcards.length });
        
        if (result.data) {
          setMultipliers(result.data.multipliers);
          setTotalMultiplier(result.data.totalMultiplier);
        }
      } catch (error) {
        console.error('Error fetching stack and multipliers:', error);
      }
    };

    fetchStackAndMultipliers();
  }, [stackId]);

  const handleAnswer = async (flashcardId, isCorrect) => {
    setAnswerTracker(prev => {
      const newTracker = { ...prev, [flashcardId]: isCorrect };
      const newCorrectAnswers = Object.values(newTracker).filter(Boolean).length;
      setCorrectAnswers(newCorrectAnswers);
      
      // Update the multipliers with new correct answer count
      if (multipliers) {
        const newCorrectAnswerMultiplier = Math.min(1.00 + (newCorrectAnswers * 0.01), 1.50);
        const updatedMultipliers = {
          ...multipliers,
          correctAnswer: newCorrectAnswerMultiplier
        };
        setMultipliers(updatedMultipliers);
        
        // Recalculate total multiplier
        const newTotalMultiplier = (updatedMultipliers.consecutiveDays + 
                                       updatedMultipliers.consecutiveStacks + 
                                       updatedMultipliers.stackLength + 
                                       updatedMultipliers.correctAnswer) - 3;
        setTotalMultiplier(newTotalMultiplier);
      }
      
      return newTracker;
    });
  };

  const handleStudyComplete = async (knownCount, unknownCount) => {
    const totalCards = knownCount + unknownCount;
    try {
      const functions = getFunctions(app);
      const handleStackCompletion = httpsCallable(functions, 'handleStackCompletion');
      const result = await handleStackCompletion({
        stackId,
        correctAnswers, // Pass the tracked correct answers
        totalCards
      });

      console.log('handleStackCompletion result:', result);

      const { pointsEarned, newLevel, newLevelProgress, multipliers, completedStacksToday } = result.data;

      setMultipliers(multipliers);
      const calculatedTotalMultiplier = Object.values(multipliers).reduce((acc, val) => acc * val, 1);
      setTotalMultiplier(calculatedTotalMultiplier);

      const navigationState = {
        knownCount,
        unknownCount,
        totalCards,
        pointsEarned,
        newLevel,
        newLevelProgress,
        multipliers,
        completedStacksToday,
      };

      console.log('Navigating to /result with state:', navigationState);

      navigate('/result', { 
        state: {
          ...navigationState,
          initialLevelProgress: result.data.initialLevelProgress,
          newLevelProgress: result.data.newLevelProgress,
        }
      });
    } catch (error) {
      console.error('Error completing stack:', error);
      alert('An error occurred while completing the stack. Please try again.');
    }
  };

  const getFlameProps = (multiplier) => {
    if (multiplier >= 3.0) return { size: 32 }; // Extreme
    if (multiplier >= 2.5) return { size: 28 }; // High
    if (multiplier >= 1.5) return { size: 24 }; // Medium
    return { size: 20 }; // Low
  };

  const FlameIcon = ({ multiplier }) => {
    const { size } = getFlameProps(multiplier);
    return <Flame color="#FFFF00" size={size} />; // Bright yellow color
  };

  const handleOpenMultiplierModal = async () => {
    setShowMultiplierModal(true);
  };

  if (!stack) return <div className="container">Loading...</div>;

  return (
    <div className="study-page bg-white min-h-screen">
      <Header />
      <div className="study-page-content max-w-[98%] mx-auto px-2 py-4">
        <div className="flex justify-between items-center mb-4 study-page-header">
          <h2 className="stack-name text-2xl font-bold" style={{ color: '#4b4b4a' }}>{stack.name}</h2>
          <Button 
            onClick={handleOpenMultiplierModal}
            className="global-button multiplier-button"
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <FlameIcon multiplier={totalMultiplier} />
              <span className="ml-2">Multiplier: {totalMultiplier.toFixed(2)}x</span>
            </span>
          </Button>
        </div>
        <Flashcards 
          flashcards={stack.flashcards} 
          onAnswer={(flashcardId, isCorrect) => handleAnswer(flashcardId, isCorrect)}
          onComplete={handleStudyComplete}
        />
      </div>
      {showMultiplierModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg relative">
            <h3 className="text-lg font-semibold mb-4">Multiplier Details</h3>
            <ul className="text-base space-y-2">
              <li>Consecutive Days: {multipliers?.consecutiveDays?.toFixed(2) || '1.00'}x</li>
              <li>Consecutive Stacks: {multipliers?.consecutiveStacks?.toFixed(2) || '1.00'}x</li>
              <li>Stack Length: {multipliers?.stackLength?.toFixed(2) || '1.00'}x</li>
              <li>Correct Answer: {multipliers?.correctAnswer?.toFixed(2) || '1.00'}x</li>
            </ul>
            <Button 
              onClick={() => setShowMultiplierModal(false)}
              className="global-button transparent-button mt-4 w-full"
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudyPage;
