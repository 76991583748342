import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth, updatePassword, deleteUser, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header/Header'; // Import the Header component
import './Settings.css'; // Make sure to create this CSS file
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, 
  AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, 
  AlertDialogTrigger } from "../components/ui/alert-dialog";

function Settings() {
  const navigate = useNavigate();
  const { currentUser, signOut } = useAuth();
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [justCancelled, setJustCancelled] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [password, setPassword] = useState('');
  const [showReauthDialog, setShowReauthDialog] = useState(false);
  const functions = getFunctions();

  const fetchSubscriptionData = useCallback(async () => {
    const db = getFirestore();
    const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      setIsSubscribed(userData.isSubscribed || false);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      setEmail(currentUser.email);
      fetchSubscriptionData();
    }
  }, [currentUser, fetchSubscriptionData]);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      await updatePassword(auth.currentUser, newPassword);
      setMessage('Password updated successfully');
      setNewPassword('');
    } catch (error) {
      setError(`Error updating password: ${error.message}`);
    }
  };

  const handleCancelSubscriptionClick = () => {
    setShowCancelConfirmation(true);
  };

  const handleConfirmCancellation = useCallback(async () => {
    if (currentUser && !isProcessing) {
      setIsProcessing(true);
      try {
        setMessage('Cancelling subscription...');
        const cancelSubscriptionFunction = httpsCallable(functions, 'cancelSubscription');
        const result = await cancelSubscriptionFunction();
        setMessage(result.data.message);
        setIsSubscribed(false);
        setJustCancelled(true);
        setError('');
      } catch (error) {
        setMessage('');
        setError(`Error cancelling subscription: ${error.message}`);
      } finally {
        setIsProcessing(false);
        setShowCancelConfirmation(false);
      }
    }
  }, [currentUser, functions, isProcessing]);

  const debouncedCancelSubscription = useCallback(
    debounce(() => {
      handleConfirmCancellation();
    }, 300),
    [handleConfirmCancellation]
  );

  const handleCancelConfirmation = () => {
    setShowCancelConfirmation(false);
  };

  const handleReauthenticate = async () => {
    try {
      const auth = getAuth();
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        password
      );
      await reauthenticateWithCredential(auth.currentUser, credential);
      setShowReauthDialog(false);
      setPassword('');
      // Continue with account deletion
      await proceedWithDeletion();
    } catch (error) {
      console.error('Reauthentication error:', error);
      setError('Invalid password. Please try again.');
    }
  };

  const proceedWithDeletion = async () => {
    try {
      // Delete all user's stacks and flashcards
      const deleteUserData = httpsCallable(functions, 'deleteUserData');
      await deleteUserData({ userId: currentUser.uid });

      // Cancel subscription if exists
      if (isSubscribed) {
        const cancelSubscription = httpsCallable(functions, 'cancelSubscription');
        await cancelSubscription();
      }

      // Delete user authentication
      const auth = getAuth();
      await deleteUser(auth.currentUser);
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Error deleting account:', error);
      setError('Failed to delete account. Please try again.');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      setIsDeleting(true);
      setShowReauthDialog(true);
    } catch (error) {
      console.error('Error deleting account:', error);
      setError('Failed to delete account. Please try again.');
      setIsDeleting(false);
    }
  };

  return (
    <div className="settings-page">
      <Header />
      <div className="settings-container">
        <h1 className="settings-header">Settings</h1>
        
        <div className="settings-section">
          <h2>Change Password</h2>
          <p>Update your account password here. We recommend using a strong, unique password.</p>
          <form onSubmit={handlePasswordChange} className="password-form">
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="New Password"
              className="password-input"
            />
            <button type="submit" className="change-password-button">
              Change Password
            </button>
          </form>
        </div>

        <div className="settings-section">
          <h2>Subscription Management</h2>
          {justCancelled ? (
            <p className="subscription-status cancelled">{message}</p>
          ) : isSubscribed ? (
            <>
              <p className="subscription-status active">Your subscription is active</p>
              <button
                onClick={handleCancelSubscriptionClick}
                className="cancel-subscription-button black-button"
                disabled={isProcessing}
              >
                Cancel Subscription
              </button>
            </>
          ) : (
            <p className="subscription-status">You don't have an active subscription.</p>
          )}
        </div>

        <div className="settings-section">
          <h2>Account Management</h2>
          <AlertDialog>
            <AlertDialogTrigger className="cancel-subscription-button black-button">
              Delete Account
            </AlertDialogTrigger>
            <AlertDialogContent className="modal-dialog">
              <AlertDialogHeader>
                <AlertDialogTitle className="settings-header">Are you absolutely sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. This will permanently delete your account
                  and remove all your data from our servers.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel className="keep-subscription-button">Cancel</AlertDialogCancel>
                <AlertDialogAction 
                  onClick={handleDeleteAccount}
                  disabled={isDeleting}
                  className="confirm-cancel-button"
                >
                  {isDeleting ? 'Deleting...' : 'Delete Account'}
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>

        <div className="settings-section">
          <h2>Contact Us</h2>
          <p>Need help or have questions? Reach out to our support team:</p>
          <a href="mailto:support@stack-hero.info" className="contact-email">
            support@stack-hero.info
          </a>
        </div>

        {error && <div className="message error">{error}</div>}

        {showCancelConfirmation && (
          <div className="modal-overlay">
            <div className="modal-content">
              <p>Are you sure you want to cancel your subscription?</p>
              <div className="modal-buttons">
                <button onClick={handleCancelConfirmation} className="keep-subscription-button">
                  No, Keep
                </button>
                <button
                  onClick={debouncedCancelSubscription}
                  className="confirm-cancel-button"
                  disabled={isProcessing}
                >
                  {isProcessing ? 'Processing...' : 'Yes, Cancel'}
                </button>
              </div>
            </div>
          </div>
        )}

        {showReauthDialog && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h2>Confirm Your Password</h2>
              <p>Please enter your password to continue</p>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                className="password-input"
              />
              <div className="modal-buttons">
                <button 
                  onClick={() => {
                    setShowReauthDialog(false);
                    setPassword('');
                    setIsDeleting(false);
                  }} 
                  className="keep-subscription-button"
                >
                  Cancel
                </button>
                <button
                  onClick={handleReauthenticate}
                  className="confirm-cancel-button"
                  disabled={!password}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Settings;