import { useEffect } from 'react';

const SleekplanWidget = () => {
    useEffect(() => {
        // Check for both script and initialization
        if (document.getElementById('sleekplan-script') || window.$sleek) {
            return;
        }

        // Create a promise to handle script loading
        const loadSleekplan = new Promise((resolve, reject) => {
            try {
                // Create initialization script first
                const initScript = document.createElement('script');
                initScript.textContent = `
                    if (!window.$sleek) {
                        window.$sleek = [];
                        window.SLEEK_PRODUCT_ID = 366754703;
                    }
                `;
                document.head.appendChild(initScript);

                // Then load the main script
                const mainScript = document.createElement('script');
                mainScript.src = 'https://client.sleekplan.com/sdk/e.js';
                mainScript.id = 'sleekplan-script';
                mainScript.async = true;

                mainScript.onload = () => {
                    console.log('Sleekplan script loaded successfully');
                    resolve();
                };

                mainScript.onerror = (error) => {
                    console.error('Error loading Sleekplan script:', error);
                    reject(error);
                };

                // Small delay before appending main script
                setTimeout(() => {
                    document.head.appendChild(mainScript);
                }, 100);
            } catch (error) {
                reject(error);
            }
        });

        // Handle script loading
        loadSleekplan.catch((error) => {
            console.error('Sleekplan initialization failed:', error);
        });

        // Cleanup function
        return () => {
            const script = document.getElementById('sleekplan-script');
            if (script && script.parentNode) {
                script.parentNode.removeChild(script);
            }
        };
    }, []); // Empty dependency array ensures this runs only once

    return (
        <div 
            id="sleekplan-container" 
            style={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                zIndex: 1000
            }}
        />
    );
};

export default SleekplanWidget; 