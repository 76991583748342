import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Settings, LogOut } from 'lucide-react';
import styles from './Header.module.css';
import logo from '../../assets/images/header logo.png';

export default function Header() {
  const { currentUser, signOut } = useAuth();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <header className={styles.appHeader}>
      <div className={styles.headerContent}>
        <div className={styles.headerLeft}>
          <Link to="/" className={styles.logoContainer}>
            <img src={logo} alt="StackHero Logo" className={styles.logo} />
          </Link>
        </div>
        <nav className={styles.headerRight}>
          {currentUser && (
            <>
              {!currentUser.isSubscribed && (
                <Link to="/subscription" className={`${styles.navLink} ${styles.upgradeButton}`}>Upgrade</Link>
              )}
              <Link to="/settings" className={styles.navLink}>
                {isMobile ? <Settings className={styles.icon} /> : 'Settings'}
              </Link>
              <button onClick={handleSignOut} className={`${styles.navLink} ${styles.signOutBtn}`}>
                {isMobile ? <LogOut className={styles.icon} /> : 'Sign Out'}
              </button>
            </>
          )}
          {!currentUser && (
            <Link to="/login" className={styles.navLink}>Login</Link>
          )}
        </nav>
      </div>
    </header>
  );
}
