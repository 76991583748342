import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { ranks } from '../../utils/ranks';
import Header from '../Header/Header';
import './HeroPage.css';

const db = getFirestore();
const storage = getStorage();

const HeroPage = () => {
    const { currentUser } = useAuth();
    const [userLevel, setUserLevel] = useState(1);
    const [heroes, setHeroes] = useState([]);
    const [selectedHero, setSelectedHero] = useState(null);

    useEffect(() => {
        const fetchUserLevel = async () => {
            if (currentUser && currentUser.uid) {
                const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
                if (userDoc.exists()) {
                    setUserLevel(userDoc.data().level);
                }
            }
        };

        fetchUserLevel();
    }, [currentUser]);

    useEffect(() => {
        const fetchHeroes = async () => {
            const unlockedHeroes = ranks.filter(rank => rank.id <= userLevel);
            const heroesWithImages = await Promise.all(unlockedHeroes.map(async (hero) => {
                try {
                    const imageRef = ref(storage, `rank_images/${hero.id.toString().padStart(2, '0')}.jpg`);
                    const url = await getDownloadURL(imageRef);
                    return { ...hero, imageUrl: url };
                } catch (error) {
                    console.error('Error fetching hero image:', error);
                    return { ...hero, imageUrl: '/default_rank.jpg' };
                }
            }));
            setHeroes(heroesWithImages);
        };

        fetchHeroes();
    }, [userLevel]);

    const openModal = (hero) => {
        setSelectedHero(hero);
    };

    const closeModal = () => {
        setSelectedHero(null);
    };

    return (
        <div className="hero-page">
            <Header />
            <div className="hero-grid">
                {heroes.map((hero) => (
                    <div key={hero.id} className="hero-card" onClick={() => openModal(hero)}>
                        <img src={hero.imageUrl} alt={hero.rank} className="hero-avatar" />
                        <p className="hero-rank">{hero.rank}</p>
                        <p className="hero-level">Level {hero.id}</p>
                    </div>
                ))}
            </div>
            {selectedHero && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <img src={selectedHero.imageUrl} alt={selectedHero.rank} className="full-size-avatar" />
                        <h2>{selectedHero.rank}</h2>
                        <p className="hero-level">Level {selectedHero.id}</p>
                        <button className="close-button" onClick={closeModal}>×</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HeroPage;
