import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from "../ui/button";
import { Card } from "../ui/card";
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { getRankByLevel } from '../../utils/ranks';
import './ResultPage.css';

const storage = getStorage();

const ResultPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [animationStep, setAnimationStep] = useState(0);
    const [showTotalMultiplier, setShowTotalMultiplier] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [progressAnimation, setProgressAnimation] = useState(0);
    const { 
        knownCount = 0, 
        unknownCount = 0, 
        totalCards = 0, 
        pointsEarned = 0, 
        newLevel = 1, 
        initialLevelProgress = 0,
        newLevelProgress = 0, 
        multipliers = {}
    } = location.state || {};
    const [animationPhase, setAnimationPhase] = useState(0);
    const [displayedLevel, setDisplayedLevel] = useState(newLevel); // Start with current level instead of previous level
    const [displayedProgress, setDisplayedProgress] = useState(initialLevelProgress);
    const [showNewRankModal, setShowNewRankModal] = useState(false);
    const [newRankImageUrl, setNewRankImageUrl] = useState('');

    // Add this logging at the start of component
    console.log('ResultPage initial state:', location.state);

    const handleReturnHome = () => {
        navigate('/home');
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (animationStep < 4) {
                setAnimationStep(animationStep + 1);
            } else if (animationStep === 4) {
                setShowTotalMultiplier(true);
                setAnimationStep(animationStep + 1);
            } else if (animationStep === 5) {
                setShowProgressBar(true);
            }
        }, 300);

        return () => clearTimeout(timer);
    }, [animationStep]);

    useEffect(() => {
        if (showProgressBar) {
            animateLevelProgress();
        }
    }, [showProgressBar]);

    useEffect(() => {
        if (showProgressBar) {
            const timer = setTimeout(() => {
                setProgressAnimation(newLevelProgress);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [showProgressBar, newLevelProgress]);

    const popAnimation = {
        scale: [1, 1.2, 1],
        transition: { duration: 0.3 }
    };

    const calculateTotalMultiplier = (multipliers) => {
        return (
            multipliers.consecutiveDays +
            multipliers.consecutiveStacks +
            multipliers.stackLength +
            multipliers.correctAnswer
        ) - 3;
    };

    const animateLevelProgress = () => {
        const animationDuration = 1000;
        const hasLeveledUp = newLevelProgress < initialLevelProgress;

        if (hasLeveledUp) {
            // Full level-up animation sequence
            setAnimationPhase(1);
            setDisplayedProgress(100);

            setTimeout(() => {
                setAnimationPhase(2);
                setDisplayedLevel(prevLevel => prevLevel + 1);
                setDisplayedProgress(0);
            }, animationDuration + 500);

            setTimeout(() => {
                setAnimationPhase(3);
                setDisplayedProgress(newLevelProgress);
            }, animationDuration + 600);

            setTimeout(() => {
                fetchNewRankImage();
                setShowNewRankModal(true);
            }, (animationDuration * 3) + 1000);
        } else {
            // Simple progress update
            setAnimationPhase(3);
            setDisplayedProgress(newLevelProgress);
        }
    };

    const fetchNewRankImage = async () => {
        try {
            const imageRef = ref(storage, `rank_images/${newLevel.toString().padStart(2, '0')}.jpg`);
            const url = await getDownloadURL(imageRef);
            setNewRankImageUrl(url);
        } catch (error) {
            console.error('Error fetching new rank image:', error);
            setNewRankImageUrl('/default_rank.jpg');
        }
    };

    const closeNewRankModal = () => {
        setShowNewRankModal(false);
    };

    return (
        <div className="result-page">
            <h1 className="text-2xl font-bold mb-4">Session Results</h1>
            <Card className="result-card mb-4">
                <div className="result-stats">
                    <div className="stat-item">
                        <span className="stat-label">Total Cards:</span>
                        <span className="stat-value">{totalCards}</span>
                    </div>
                    <div className="stat-item">
                        <span className="stat-label">Correct:</span>
                        <span className="stat-value">{knownCount}</span>
                    </div>
                    <div className="stat-item">
                        <span className="stat-label">Incorrect:</span>
                        <span className="stat-value">{unknownCount}</span>
                    </div>
                </div>
            </Card>
            <Card className="result-card mb-4">
                <h2 className="text-xl font-bold mb-2 highlight-text">Points Earned</h2>
                <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.3 }}
                    className="points-earned"
                >
                    {pointsEarned}
                </motion.div>
                <div className="multipliers">
                    <h3 className="text-lg font-semibold mb-1 highlight-text">Multipliers:</h3>
                    <ul>
                        <AnimatePresence>
                            {animationStep >= 1 && (
                                <motion.li
                                    key="consecutiveDays"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0, ...popAnimation }}
                                    exit={{ opacity: 0, y: -20 }}
                                    transition={{ duration: 0.2 }}
                                >
                                    Consecutive Days: {multipliers.consecutiveDays?.toFixed(2) || '1.00'}x
                                </motion.li>
                            )}
                            {animationStep >= 2 && (
                                <motion.li
                                    key="consecutiveStacks"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0, ...popAnimation }}
                                    exit={{ opacity: 0, y: -20 }}
                                    transition={{ duration: 0.2 }}
                                >
                                    Consecutive Stacks: {multipliers.consecutiveStacks?.toFixed(2) || '1.00'}x
                                </motion.li>
                            )}
                            {animationStep >= 3 && (
                                <motion.li
                                    key="stackLength"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0, ...popAnimation }}
                                    exit={{ opacity: 0, y: -20 }}
                                    transition={{ duration: 0.2 }}
                                >
                                    Stack Length: {multipliers.stackLength?.toFixed(2) || '1.00'}x
                                </motion.li>
                            )}
                            {animationStep >= 4 && (
                                <motion.li
                                    key="correctAnswer"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0, ...popAnimation }}
                                    exit={{ opacity: 0, y: -20 }}
                                    transition={{ duration: 0.2 }}
                                >
                                    Correct Answer: {multipliers.correctAnswer?.toFixed(2) || '1.00'}x
                                </motion.li>
                            )}
                        </AnimatePresence>
                    </ul>
                    {showTotalMultiplier && (
                        <motion.div
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.3 }}
                            className="total-multiplier"
                        >
                            Total Multiplier: {calculateTotalMultiplier(multipliers).toFixed(2)}x
                        </motion.div>
                    )}
                </div>
            </Card>
            <Card className="result-card mb-4">
                <h2 className="text-xl font-bold mb-2 highlight-text">Level Progress</h2>
                <div className="level-info">
                    <motion.span 
                        className="level"
                        key={displayedLevel}
                        initial={{ scale: 1 }}
                        animate={{ scale: [1, 1.2, 1] }}
                        transition={{ duration: 0.3 }}
                    >
                        Level {displayedLevel}
                    </motion.span>
                    {showProgressBar && (
                        <div className="progress-element">
                            <div className="progress-container">
                                <div 
                                    className="progress-bar"
                                    style={{ 
                                        width: `${displayedProgress}%`,
                                        transition: animationPhase === 2 ? 'none' : 'width 1s ease-in-out'
                                    }}
                                ></div>
                            </div>
                        </div>
                    )}
                </div>
            </Card>
            <Button onClick={handleReturnHome} className="return-home-button">
                Return to Home
            </Button>
            
            {showNewRankModal && (
                <div className="modal-overlay" onClick={closeNewRankModal}>
                    <div className="modal-content new-rank-modal" onClick={(e) => e.stopPropagation()}>
                        <h2 className="new-rank-title">Your New Hero</h2>
                        <div className="new-rank-avatar-container">
                            {newRankImageUrl && <img src={newRankImageUrl} alt="New Rank" className="new-rank-avatar" />}
                        </div>
                        <p className="new-rank-name">
                            {getRankByLevel(newLevel) || `Unknown Rank (Level ${newLevel})`}
                        </p>
                        <button className="close-button" onClick={closeNewRankModal}>×</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ResultPage;
