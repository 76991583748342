import React, { useState, useEffect } from 'react';
import { generateFlashcards } from '../../services/flashcardService';
import { uploadFile } from '../../services/fileUploadService';
import { saveFlashcards, getUserSubscriptionStatus } from '../../services/firestoreService';
import { readFileContent } from '../../utils/fileProcessing';
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Upload, Plus, Loader2 } from 'lucide-react';
import Slider from '../Slider';
import './FlashcardManager.css';

const FlashcardManager = ({ onApiResponse, userNickname }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [stackName, setStackName] = useState('');
	const [numFlashcards, setNumFlashcards] = useState(10);
	const [selectedFile, setSelectedFile] = useState(null);
	const [errors, setErrors] = useState({ stackName: false, file: false });
	const [isSubscribed, setIsSubscribed] = useState(false);
	const [showWarning, setShowWarning] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
	const [errorMessage, setErrorMessage] = useState('');

	useEffect(() => {
		const checkSubscription = async () => {
			const status = await getUserSubscriptionStatus();
			setIsSubscribed(status);
		};
		checkSubscription();
	}, []);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		setSelectedFile(file);
		setErrors(prev => ({ ...prev, file: false }));
	};

	const handleCreateStack = async () => {
		setErrorMessage('');

		const newErrors = {
			stackName: !stackName.trim(),
			file: !selectedFile
		};
		setErrors(newErrors);

		if (newErrors.stackName || newErrors.file) {
			return;
		}

		setIsLoading(true);
		try {
			const uploadedFileUrl = await uploadFile(selectedFile);
			const fileContent = await readFileContent(selectedFile);
			const flashcards = await generateFlashcards(fileContent, isSubscribed ? numFlashcards : Math.min(numFlashcards, 15));
			const result = await saveFlashcards(flashcards, stackName, isSubscribed);
			onApiResponse(result);
			
			// Reset all fields after successful creation
			setStackName('');
			setNumFlashcards(10);
			setSelectedFile(null);
			setErrors({ stackName: false, file: false });
			setShowWarning(false);
			
			// Reset the file input
			const fileInput = document.getElementById('file-upload');
			if (fileInput) {
				fileInput.value = '';
			}
		} catch (error) {
			console.error('Error creating stack:', error);
			setErrorMessage(error.message);
		} finally {
			setIsLoading(false);
		}
	};

	const handleSliderChange = (value) => {
		setNumFlashcards(value);
		setShowWarning(!isSubscribed && value > 15);
	};

	const getDisplayFileName = () => {
		if (!selectedFile) return 'Choose file';
		return selectedFile.name.length > 20 
			? selectedFile.name.substring(0, 17) + '...' 
			: selectedFile.name;
	};

	return (
		<div className="flashcard-manager">
			{errorMessage && (
				<div className="error-message" style={{
					color: '#ef4444',
					backgroundColor: '#fee2e2',
					padding: '0.75rem',
					borderRadius: '0.375rem',
					marginBottom: '1rem',
					border: '1px solid #fca5a5'
				}}>
					{errorMessage}
				</div>
			)}

			<div className="input-group">
				<label>Stack Name</label>
				<Input
					placeholder="Enter stack name"
					value={stackName}
					onChange={(e) => {
						setStackName(e.target.value);
						setErrors(prev => ({ ...prev, stackName: false }));
					}}
					className={`stack-name-input ${errors.stackName ? 'highlight' : ''}`}
				/>
			</div>

			<div className="input-group">
				<label>Upload File</label>
				<Button 
					onClick={() => document.getElementById('file-upload').click()} 
					className={`browse-button ${errors.file ? 'highlight' : ''}`}
				>
					<Upload className="mr-2 h-4 w-4" /> {getDisplayFileName()}
				</Button>
				<input
					id="file-upload"
					type="file"
					onChange={handleFileChange}
					accept=".pdf,.doc,.docx,.txt"
					style={{ display: 'none' }}
				/>
			</div>

			<div className="input-group">
				<label>Number of Flashcards</label>
				<div className="slider-wrapper">
					<div className="slider-container">
						<Slider
							min={1}
							max={50}
							value={numFlashcards}
							onChange={handleSliderChange}
							showValueLabel={false} // Always hide the floating label
						/>
					</div>
					<span className="flashcard-count">{numFlashcards}</span>
				</div>
				{showWarning && (
					<p className="warning-message">Values above 15 require a subscription. Your stack will be limited to 15 flashcards.</p>
				)}
			</div>

			<Button onClick={handleCreateStack} className="create-stack-button" disabled={isLoading}>
				{isLoading ? (
					<>
						<Loader2 className="mr-2 h-4 w-4 animate-spin" /> 
						<span className="creating-text">Generating flashcards... Larger files take longer</span>
					</>
				) : (
					<>
						<Plus className="mr-2 h-4 w-4" /> Create Stack
					</>
				)}
			</Button>
		</div>
	);
};

export default FlashcardManager;
