import React from 'react';
import Header from '../components/Header/Header';
import styles from './PrivacyPolicy.module.css';
import { privacyPolicyContent } from '../assets/privacy-policy';

const PrivacyPolicy = () => {
  return (
    <div className={styles.policyContainer}>
      <Header />
      <div 
        className={styles.content}
        dangerouslySetInnerHTML={{
          __html: `
            <style>
              [data-custom-class='body'], [data-custom-class='body'] * {
                background: transparent !important;
              }
              [data-custom-class='title'], [data-custom-class='title'] * {
                font-family: Arial !important;
                font-size: 26px !important;
                color: #000000 !important;
              }
              [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
                font-family: Arial !important;
                color: #595959 !important;
                font-size: 14px !important;
              }
              [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
                font-family: Arial !important;
                font-size: 19px !important;
                color: #000000 !important;
              }
              [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
                font-family: Arial !important;
                font-size: 17px !important;
                color: #000000 !important;
              }
              [data-custom-class='body_text'], [data-custom-class='body_text'] * {
                color: #595959 !important;
                font-size: 14px !important;
                font-family: Arial !important;
              }
              [data-custom-class='link'], [data-custom-class='link'] * {
                color: #3030F1 !important;
                font-size: 14px !important;
                font-family: Arial !important;
                word-break: break-word !important;
              }
            </style>
            ${privacyPolicyContent}
          `
        }} 
      />
    </div>
  );
};

export default PrivacyPolicy; 