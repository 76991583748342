import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './Views/LandingPage';
import HomePage from './Views/HomePage';
import Settings from './Views/Settings';
import SubscriptionPage from './Views/SubscriptionPage';
import SubscriptionCancellation from './Views/SubscriptionCancellation';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import LoginPage from './Views/LoginPage';
import WelcomePage from './Views/WelcomePage';
import StudyPage from './Views/StudyPage';
import ResultPage from './components/ResultPage/ResultPage';
import HeroPage from './components/HeroPage/HeroPage';
import PrivacyPolicy from './Views/PrivacyPolicy';

// Protected Route component (unchanged)
const ProtectedRoute = ({ children }) => {
  const auth = useAuth();
  if (!auth || !auth.currentUser) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

function AppRoutes() {
  const auth = useAuth();

  return (
    <Routes>
      <Route path="/" element={auth && auth.currentUser ? <Navigate to="/home" replace /> : <LandingPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/home" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
      <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
      <Route path="/subscription" element={<ProtectedRoute><SubscriptionPage /></ProtectedRoute>} />
      <Route path="/cancel-subscription" element={<ProtectedRoute><SubscriptionCancellation /></ProtectedRoute>} />
      <Route path="/welcome" element={<ProtectedRoute><WelcomePage /></ProtectedRoute>} />
      <Route path="/study/:stackId" element={<ProtectedRoute><StudyPage /></ProtectedRoute>} />
      <Route path="/result" element={<ProtectedRoute><ResultPage /></ProtectedRoute>} />
      <Route path="/heroes" element={<ProtectedRoute><HeroPage /></ProtectedRoute>} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      {/* Catch-all route for any unmatched paths */}
      <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppRoutes />
      </Router>
    </AuthProvider>
  );
}

export default App;
