import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { getRankByLevel } from '../../utils/ranks';
import { useNavigate } from 'react-router-dom';
import './HeroStats.css';

const db = getFirestore();
const storage = getStorage();

const HeroStats = () => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userStats, setUserStats] = useState(null);
    const [rankImageUrl, setRankImageUrl] = useState('');

    useEffect(() => {
        const fetchUserStats = async () => {
            if (currentUser && currentUser.uid) {
                const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
                if (userDoc.exists()) {
                    setUserStats(userDoc.data());
                }
            }
        };

        fetchUserStats();
    }, [currentUser]);

    useEffect(() => {
        const fetchRankImage = async () => {
            if (userStats && userStats.level) {
                try {
                    const imageRef = ref(storage, `rank_images/${userStats.level.toString().padStart(2, '0')}.jpg`);
                    const url = await getDownloadURL(imageRef);
                    setRankImageUrl(url);
                } catch (error) {
                    console.error('Error fetching rank image:', error);
                    // Set a default image URL if fetch fails
                    setRankImageUrl('/default_rank.jpg');
                }
            }
        };

        fetchRankImage();
    }, [userStats]);

    if (!userStats) {
        return <div>Loading...</div>;
    }

    console.log('User stats:', userStats);
    console.log('User level:', userStats?.level);

    const rank = getRankByLevel(userStats.level) || `Unknown Rank (Level ${userStats.level})`;

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleMyHeroesClick = () => {
        navigate('/heroes');
    };

    return (
        <div className="hero-stats">
            <button className="my-heroes-button" onClick={handleMyHeroesClick}>
                My Heroes
            </button>
            <div className="user-info">
                <div className="avatar-container" onClick={openModal}>
                    {rankImageUrl && <img src={rankImageUrl} alt={rank} className="rank-avatar" />}
                </div>
                <div className="user-details">
                    <span className="username">{userStats.nickname || 'Guest'}</span>
                    <span className="rank">{rank}</span>
                </div>
            </div>
            <div className="level-info">
                <div className="level-text">
                    <span>Level {userStats.level}</span>
                </div>
                <div className="progress-container">
                    <div 
                        className="progress-bar no-transition" 
                        style={{ width: `${userStats.levelProgress || 0}%` }}
                    ></div>
                </div>
            </div>
            {isModalOpen && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        {rankImageUrl && <img src={rankImageUrl} alt={rank} className="full-size-avatar" />}
                        <button className="close-button" onClick={closeModal}>×</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HeroStats;
