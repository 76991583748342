import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header/Header';
import LeftPanel from '../components/LeftPanel/LeftPanel';
import FlashcardManager from '../components/FlashcardManager/FlashcardManager';
import StackPreview from '../components/StackPreview/StackPreview';
import HeroStats from '../components/HeroStats/HeroStats';
import { getFlashcardsInStack, getStacks, getStackWithFirstQuestion, getUserNickname, getUserStats, updateStackName } from '../services/firestoreService';
import { checkSubscriptionStatus } from '../services/authService';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import './HomePage.css';
import { Spinner } from '../components/ui/spinner'; // Make sure to create this component
import { purchaseSubscription, isAndroidApp, checkPlayBillingSubscription } from '../utils/billingUtils';
import SleekplanWidget from '../components/SleekplanWidget/SleekplanWidget';

const HomePage = () => {
    const [currentStackId, setCurrentStackId] = useState(null);
    const [flashcards, setFlashcards] = useState([]);
    const [currentStackName, setCurrentStackName] = useState('');
    const [stacks, setStacks] = useState([]);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [selectedStack, setSelectedStack] = useState(null);
    const [selectedStackId, setSelectedStackId] = useState(null);
    const { currentUser, updateUser } = useAuth();
    const navigate = useNavigate();
    const [userNickname, setUserNickname] = useState('');
    const [userStats, setUserStats] = useState({ points: 0, level: 1, levelProgress: 0 });
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [showMobilePanel, setShowMobilePanel] = useState(false);
    const [isHeroStatsLoading, setIsHeroStatsLoading] = useState(true);
    const [newStackCreated, setNewStackCreated] = useState(false);
    const topRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const loadStacks = useCallback(async () => {
        if (currentUser) {
            try {
                console.log('Loading stacks for user:', currentUser.uid);
                console.log('Current user object:', currentUser);
                const loadedStacks = await getStacks();
                setStacks(loadedStacks);
                
                if (loadedStacks.length > 0 && !selectedStackId) {
                    const firstStack = loadedStacks[0];
                    handleStackSelect(firstStack.id, firstStack.name);
                }
            } catch (error) {
                console.error("Error loading stacks:", error);
            }
        } else {
            console.log('No current user in loadStacks');
        }
    }, [currentUser, selectedStackId]);

    const checkSubscription = useCallback(async () => {
        if (currentUser) {
            try {
                console.log('Checking subscription');
                const subscribed = await checkSubscriptionStatus(currentUser);
                setIsSubscribed(subscribed);
                const nickname = await getUserNickname(currentUser.uid);
                setUserNickname(nickname);
                const stats = await getUserStats(currentUser.uid);
                setUserStats(stats);
            } catch (error) {
                console.error("Error checking subscription:", error);
            }
        }
    }, [currentUser]);

    const loadHeroStats = useCallback(async () => {
        if (currentUser) {
            setIsHeroStatsLoading(true);
            try {
                // Try to get cached data first
                const cachedStats = JSON.parse(localStorage.getItem('heroStats'));
                if (cachedStats) {
                    setUserNickname(cachedStats.nickname);
                    setUserStats(cachedStats.stats);
                    setIsHeroStatsLoading(false);
                }

                // Fetch fresh data
                const nickname = await getUserNickname(currentUser.uid);
                const stats = await getUserStats(currentUser.uid);

                // Update state and cache
                setUserNickname(nickname);
                setUserStats(stats);
                localStorage.setItem('heroStats', JSON.stringify({ nickname, stats }));
            } catch (error) {
                console.error("Error loading hero stats:", error);
            } finally {
                setIsHeroStatsLoading(false);
            }
        }
    }, [currentUser]);

    useEffect(() => {
        console.log('HomePage useEffect running');
        if (currentUser) {
            loadStacks();
            checkSubscription();
            loadHeroStats();
        } else {
            console.log('No current user, redirecting to login');
            navigate('/login');
        }
    }, [currentUser, loadStacks, checkSubscription, navigate, loadHeroStats]);

    const handleStackSelect = async (stackId, stackName) => {
        console.log(`Selecting stack: ${stackId}, ${stackName}`);
        const stackData = await getStackWithFirstQuestion(stackId);
        console.log('Stack data received:', stackData);
        setSelectedStack(stackData);
        setCurrentStackId(stackId);
        setCurrentStackName(stackName);
        setSelectedStackId(stackId);
        if (isMobile) {
            setShowMobilePanel(false);
        }
    };

    const handleApiResponse = async (response) => {
        if (response && response.flashcards) {
            setFlashcards(response.flashcards);
            setCurrentStackId(response.stackId);
            setCurrentStackName(response.stackName);
            setSelectedStackId(response.stackId);
            await loadStacks();
            handleStackSelect(response.stackId, response.stackName);
            setNewStackCreated(true);
            topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (newStackCreated) {
            const timer = setTimeout(() => setNewStackCreated(false), 3000);
            return () => clearTimeout(timer);
        }
    }, [newStackCreated]);

    const handleUpdateStackName = async (stackId, newName) => {
        console.log(`Updating stack name: ${stackId}, ${newName}`);
        await updateStackName(stackId, newName);
        setStacks(prevStacks => 
            prevStacks.map(stack => 
                stack.id === stackId ? { ...stack, name: newName } : stack
            )
        );
        if (stackId === currentStackId) {
            setCurrentStackName(newName);
        }
        if (selectedStack && selectedStack.id === stackId) {
            setSelectedStack(prevStack => ({ ...prevStack, name: newName }));
        }
        console.log('Updated selectedStack:', selectedStack);
    };

    const handleDeleteStack = (stackId) => {
        setStacks(prevStacks => prevStacks.filter(stack => stack.id !== stackId));
        if (stackId === currentStackId) {
            setCurrentStackId(null);
            setCurrentStackName('');
            setSelectedStack(null);
        }
    };

    const handleCreateStack = async (newStackId, newStackName) => {
        await loadStacks();
        handleStackSelect(newStackId, newStackName);
    };

    const handleStudyClick = (stackId) => {
        navigate(`/study/${stackId}`);
    };

    const handleEditClick = (stackId) => {
        // Implement the edit functionality here
        console.log(`Editing stack with id: ${stackId}`);
        // You might want to open a modal or navigate to an edit page
    };

    const handleDeleteClick = (stackId) => {
        // Implement the delete functionality here
        console.log(`Deleting stack with id: ${stackId}`);
        // You might want to show a confirmation dialog before deleting
    };

    useEffect(() => {
        const handleResize = () => {
            const newIsMobile = window.innerWidth <= 768;
            setIsMobile(newIsMobile);
            console.log('Is Mobile:', newIsMobile); // Add this line
        };

        handleResize(); // Call it once on mount
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderYourStacksButton = () => (
        <button 
            className="your-stacks-button"
            onClick={() => setShowMobilePanel(true)}
        >
            Your Stacks
        </button>
    );

    const handleUpgradeClick = async () => {
        if (isAndroidApp()) {
            const success = await purchaseSubscription(currentUser.uid);
            if (success) {
                setIsLoading(true);
                const checkStatus = async () => {
                    const isSubscribed = await checkPlayBillingSubscription();
                    if (isSubscribed) {
                        await updateUser({ isSubscribed: true });
                        setIsLoading(false);
                        navigate('/home');
                    } else {
                        setTimeout(checkStatus, 2000);
                    }
                };
                checkStatus();
            }
        } else {
            navigate('/subscription');
        }
    };

    return (
        <div className="home-page" ref={topRef}>
            <Header />
            <SleekplanWidget />
            <div className="main-content">
                {!isMobile && (
                    <LeftPanel 
                        stacks={stacks} 
                        onStackSelect={handleStackSelect} 
                        selectedStackId={selectedStackId}
                        isMobile={false}
                    />
                )}
                <div className="right-panel narrow-panel">
                    <div className="hero-stats-container">
                        {isHeroStatsLoading ? (
                            <Spinner />
                        ) : (
                            <HeroStats 
                                userNickname={userNickname}
                                level={userStats.level}
                                levelProgress={userStats.levelProgress}
                            />
                        )}
                    </div>
                    <div className="grid grid-cols-1 gap-6">
                        <Card className="narrow-card">
                            <CardHeader className="pb-2 relative">
                                <CardTitle className="text-xl card-title">Stack Preview</CardTitle>
                                {isMobile && (
                                    <button 
                                        className="your-stacks-button"
                                        onClick={() => setShowMobilePanel(true)}
                                    >
                                        Your Stacks
                                    </button>
                                )}
                            </CardHeader>
                            <CardContent className="pt-2">
                                {selectedStack ? (
                                    <StackPreview 
                                        stack={selectedStack}
                                        onStudyClick={handleStudyClick}
                                        onUpdateStackName={handleUpdateStackName}
                                        onDeleteStack={handleDeleteStack}
                                        newStackCreated={newStackCreated}
                                    />
                                ) : (
                                    !isMobile && <p>Select a stack from the left panel</p>
                                )}
                            </CardContent>
                        </Card>

                        <Card className="narrow-card">
                            <CardHeader className="pb-2">
                                <CardTitle className="text-xl card-title">Create New Stack</CardTitle>
                            </CardHeader>
                            <CardContent className="pt-2">
                                <FlashcardManager 
                                    onApiResponse={handleApiResponse}
                                    currentStackId={currentStackId}
                                    currentStackName={currentStackName}
                                    userNickname={userNickname}
                                />
                            </CardContent>
                        </Card>
                    </div>
                </div>
                {isMobile && showMobilePanel && (
                    <div className="mobile-panel">
                        <LeftPanel 
                            stacks={stacks} 
                            onStackSelect={handleStackSelect} 
                            selectedStackId={selectedStackId}
                            isMobile={true}
                            onClose={() => setShowMobilePanel(false)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default HomePage;
